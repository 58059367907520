
import {defineComponent, ref, watch} from "vue";
import DocService from "@/core/services/DocService";
import {GetSpaceId} from "@/core/composite/composite";

export default defineComponent({
  name: "RelatedDocuments",
  props: {
    docId: {type: String,}
  },
  setup(props) {
    const documents = ref<any>([]);
    const loadDRelatedDocuments = (id) => {
      if (id) {
        DocService.relatedDocuments(id).then(res => {
          documents.value = res
        })
      } else {
        documents.value = [];
      }
    }
    loadDRelatedDocuments(props.docId);
    watch(() => props.docId, cb => {
      loadDRelatedDocuments(cb);
    })
    return {
      documents,
      ...GetSpaceId()
    }
  }
})
