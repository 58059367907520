
import {defineComponent, ref, watch} from "vue";
import DocService from "@/core/services/DocService";
import {GetSpaceId} from "@/core/composite/composite";

export default defineComponent({
  name: "DocDrafts",
  props: {
    docId: {}
  },
  setup(props) {
    const documents = ref<any>([]);
    const loadData = (id) => {
      DocService.docDrafts(id).then(res => {
        documents.value = res;
      })
    }
    loadData(props.docId);
    watch(() => props.docId, cb => {
      loadData(cb);
    })
    return {
      documents,
      loadData,
      ...GetSpaceId(),
    }
  }
})
